import "./style.scss";

import PropTypes from "prop-types";
import Icon from "../../Icons";
import { createClassName } from "../../../helpers";
import { metaIcon } from "../../../constants";

const cn = createClassName("meta");

const MetaInfo = ({
 children, iconName, iconDir, ...props
}) => (
  <div className={`${cn({ item: true })} ${props.itemClassName}`}>
    <Icon className={cn({ value: "icon" })} iconName={iconName} dir={iconDir} />
    <pre className={`${cn({ value: true })} ${props.valueClassName}`}>
      {children}
    </pre>
  </div>
);

const Meta = (props) => (
  <div className={`${cn({ wrap: true })} ${props.className}`}>
    {Object.keys(props.meta).map((key, index) => {
        const { iconName, iconDir } = props.whiteIcon ? metaIcon[key].white : metaIcon[key].gray;
        return (
          <MetaInfo key={index} iconName={iconName} iconDir={iconDir} {...props}>
            {props.meta[key]}
          </MetaInfo>
        );
      })}
    <MetaInfo iconName={metaIcon.comments.white.iconName} iconDir={metaIcon.comments.white.iconDir} {...props}>
      {props.commentsLength}
    </MetaInfo>
    {props.views && (
    <MetaInfo iconName={metaIcon.views.white.iconName} iconDir={metaIcon.views.white.iconDir} {...props}>
      {props.views}
    </MetaInfo>
      )}
  </div>
  );

Meta.defaultProps = {
  className: "",
  itemClassName: "",
  valueClassName: "",
  whiteIcon: true,
};

Meta.propTypes = {
  meta: PropTypes.object.isRequired,
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  whiteIcon: PropTypes.bool,
  views: PropTypes.object,
};

export default Meta;
