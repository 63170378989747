import { useCallback } from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

// eslint-disable-next-line react/prop-types
export const OnlineSalesImage = ({ onlineSalesImage, className = "", alt }) => {
  const image = getImage(onlineSalesImage.file);

  if (!image) {
    return (
      <img
        src={onlineSalesImage.staticPath}
        className={`page-image ${className}`}
        alt={alt}
      />
    );
  }

  return (
    <GatsbyImage
      loading="lazy"
      image={image}
      className={`page-image ${className}`}
      alt={alt}
    />
  );
};

export const useOnlineSalesImage = images => {
  const ImageComponent = useCallback(
    props => {
      const { src, alt, calssName = "" } = props;

      const image = images?.find(({ staticPath }) => staticPath === src);

      if (!image) {
        return (
          <img className={`page-image ${calssName}`} src={src} alt={alt} />
        );
      }

      return <OnlineSalesImage onlineSalesImage={image} {...props} />;
    },
    [images]
  );

  return ImageComponent;
};
