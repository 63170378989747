import "./style.scss";

import { useState } from "react";

import Email from "../../FormControl/InputField/_email";
import PrivacyCheckbox from "../../FormControl/CheckboxNew/_privacy";
import PropTypes from "prop-types";
import SubmitButton from "../../Buttons/Submit";
import { createClassName } from "../../../helpers";
import { defaultDataMapping } from "../../Api_request/dataMapping";
import { errorFooterText } from "../../Notice/constantMessage";
import loadable from '@loadable/component'
import { sendRequest } from "../../Api_request";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import TranslationWord from "../../Translate_component";

const cn = createClassName("subscribe-form");

const Notice = loadable(() => import('../../Notice'));

const SubscribeForm = props => {
  const { t } = useTranslation("forms");
  const [isSend, getSending] = useState(false);
  const [isServerResponse, setServerResponse] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      AcceptGuides: false,
    },
  });

  const onSubmit = data => {
    setLoading(true);
    sendRequest(
      "subscribe",
      defaultDataMapping(data),
      getSending,
      setServerResponse,
      setLoading
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={props.classNameForm}>
      <div className={cn("input-field", { wrap: true })}>
        <div className={cn("input-field", { email: true })}>
          <Email
            fieldClassName={cn("input-field", { email: "label" })}
            errors={errors}
            register={register}
            isErrorState={errors?.email && true}
            name="email"
          />
        </div>
        <SubmitButton        
          buttonTextKey={isServerResponse ? t("common:subscribe") : t("common:try-again")}
          className={cn("button", { wrap: true, error: Object.keys(errors).length > 0, spiner: isLoading })}
          spinerClassName={props.spinerClassName}
          isLoading={isLoading}
        />
      </div>
      <PrivacyCheckbox
        name="AcceptGuides"
        className={cn("checkbox")}
        tooltipContent={t("tooltip.subscribe")}
        register={register({ required: true })}
        isErrorState={errors?.AcceptGuides && true}
      />
      {(!isServerResponse || isSend) && (
        <Notice
          withButton={false}
          successSend={isServerResponse}
          errorFooterText={errorFooterText}
          successHeaderTitle={t("common:thanks")}
          successFooterText={t("miniForm.successFooterText")}
          className={cn("message")}
          isLoading={isLoading}
        />
      )}
    </form>
  );
};

SubscribeForm.defaultProps = {
  spinerWrapClassName: '',
};

SubscribeForm.propTypes = {
  classNameForm: PropTypes.string,
  spinerWrapClassName: PropTypes.string,
  spinerClassName: PropTypes.string,
};

export default SubscribeForm;
