import PropTypes from "prop-types";
import Icon from "./index";

const ArrowLeft = (props) => Icon({ ...props, iconName: "arrow_left_normal" });

ArrowLeft.defaultProps = {
  isAriaHidden: false,
  alt: "arrow left",
};

ArrowLeft.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default ArrowLeft;
