import "./style.scss";

import PropTypes from "prop-types";
import AccentContainer from "../../Container/_accent";
import Container from "../../Container";
import Heading from "../../Heading";
import Title from "../../Title";
import { createClassName } from "../../../helpers";

const Banner = (props) => {
  const cn = createClassName("banner");

  const subtitle = props.subtitle && (
    <Title
      level={5}
      weight="normal"
      color="white"
      className={`${cn({ subtitle: true })} ${cn({ [props.order]: true })}`}
      align={props.align}
    >
      {props.subtitle}
    </Title>
  );

  return (
    <AccentContainer>
      <Container className={cn({ wrap: true })}>
        {props.subtitleIsHeading ? (
          <Heading
            level={2}
            fontLevel={5}
            className={cn({ [props.order]: true })}
          >
            {subtitle}
          </Heading>
        ) : (
            subtitle
          )}
        {props.title && (
          <Heading
            level={1}
            color="white"
            weight="light"
            align={props.align}
            className={props.titleClassName}
          >
            {props.title}
          </Heading>
        )}
      </Container>
    </AccentContainer>
  );
};

Banner.defaultProps = {
  subtitleIsHeading: false,
  titleClassName: "",
};

Banner.propTypes = {
  titleClassName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  align: PropTypes.string,
  order: PropTypes.string,
  subtitleIsHeading: PropTypes.bool,
};

export default Banner;
