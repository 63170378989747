import { cloneElement } from "react";
import PropTypes from "prop-types";

import iconButtonProps from "../propTypes";

import { createClassName } from "../../../helpers";
import "./style.scss";

const IconButton = (props) => {
  const cn = createClassName("icon-button");

  return (
    <button
      onClick={props.handler}
      type={props.type}
      className={`
        ${cn({
          color: props.color,
          size: props.size,
        })}
        ${props.className}
      `}
      aria-label={props.ariaLabel}
      {...props.rest}
    >
      {props.children
        && cloneElement(props.children, { className: cn("icon") })}
    </button>
  );
};

IconButton.defaultProps = {
  ...iconButtonProps[0],
  label: PropTypes.string.isRequired,
};
IconButton.propTypes = {
  ...iconButtonProps[1],
  children: PropTypes.node.isRequired,
};

export default IconButton;
